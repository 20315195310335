import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['form', 'turboFrame', 'addStat']

  static values = {
    url: String
  }

  qsFromForm() {
    return new URLSearchParams(new FormData(this.formTarget));
  }

  change_category() {
    const queryString = this.qsFromForm();
    this.turboFrameTarget.src = this.urlValue + '?' + queryString.toString();
  }

  add_stat(event) {
    var list = event.target.getAttribute('list');
    var options = document.querySelectorAll('#' + list + ' option');

    const v = event.target.value.toLowerCase();
    for (const option of options) {
      if (option.innerText.toLowerCase() == v) {
        const queryString = this.qsFromForm();
        queryString.set('add_stat', event.target.value);
        this.turboFrameTarget.src = this.urlValue + '?' + queryString.toString();
        break;
      }
    }
  }
}
