import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  remoteContent = null;

  static targets = ["content"];
  static values = {
    itemId: Number
  };

  connect() {
    this.hide();
  }

  disconnect() {
    this.hide();
  }

  async show() {
    this.contentTarget.classList.remove('is-hidden')
    this.contentTarget.innerHTML = await this.fetch();
  }

  hide() {
    this.contentTarget.classList.add('is-hidden')
  }

  move(event) {
    this.contentTarget.style.top = event.offsetY + 10 + "px";
    this.contentTarget.style.left = event.offsetX + 10 + "px";
  }

  async fetch() {
    if (!this.remoteContent) {
      const response = await fetch("/items/" + this.itemIdValue + "/preview");
      this.remoteContent = await response.text();
    }

    return this.remoteContent;
  }
}

