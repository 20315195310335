import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  timeout = null;

  connect() {
  }

  search() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => { this.searchImmediate() }, 300);
  }

  searchImmediate() {
    const documentURL = new URL(document.location);

    const qs = new URLSearchParams(new FormData(this.element));
    var searchParams = new URLSearchParams();
    for (const [key, value] of qs) {
      if (value != "") {
        searchParams.append(key, value);
      }
    };

    documentURL.search = searchParams;

    // history.replaceState breaks navigation! use this instead.
    window.Turbo.navigator.history.replace({ href: documentURL.href });

    this.element.requestSubmit();
  }
}

