import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["turboFrame", "existingId", "extraFormData"];

  static values = {
    url: String
  }

  qs() {
    const ret = new URLSearchParams();

    this.existingIdTargets.forEach((t) => { ret.append(t.name, t.value); });

    this.extraFormDataTargets.forEach((t) => { ret.append(t.name, t.value); });

    return ret;
  }

  click(event) {
    var list = event.target.getAttribute('list');
    var options = document.querySelectorAll('#' + list + ' option');

    const v = event.target.value.toLowerCase();
    for (const option of options) {
      if (option.innerText.toLowerCase() == v) {
        const queryString = this.qs();
        queryString.set('add_id', option.getAttribute('data-add-id'));
        this.turboFrameTarget.src = this.urlValue + '?' + queryString.toString();
        break;
      }
    }
  }
}

