import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "hint"]

  static values = {
    url: String,
    objectId: Number,
  }

  timeout = null;

  connect() {
    this.detectImmediate()
  }

  detect() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.detectImmediate(), 500);
  }

  detectImmediate() {
    /* not needed for GEt
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    */

    const url = new URL(document.location);
    url.pathname = this.urlValue
    url.search = new URLSearchParams({
      id: this.objectIdValue, value: this.inputTarget.value
    }).toString();

    fetch(url, {
      Accept: "application/json",
      "Content-Type": "application/json",
      //"X-CSRF-Token": csrfToken, // not needed for GET
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.duplicate) {
          this.inputTarget.classList.add('is-danger');
          this.hintTarget.classList.remove('is-hidden');
          this.hintTarget.href = data.duplicate;
        } else {
          this.inputTarget.classList.remove('is-danger');
          this.hintTarget.classList.add('is-hidden');
        }
      });
  }
}

