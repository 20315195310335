import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["icon", "details"];

  static values = {
    isHidden: Boolean
  };

  connect() {
    this.setClasses();
  }

  click() {
    this.isHiddenValue = !this.isHiddenValue;
    this.setClasses();
  }

  setClasses() {
    if (this.isHiddenValue) {
      this.detailsTarget.classList.add('is-hidden')
      this.iconTarget.classList.add('fa-caret-right')
      this.iconTarget.classList.remove('fa-caret-down')
    } else {
      this.detailsTarget.classList.remove('is-hidden')
      this.iconTarget.classList.remove('fa-caret-right')
      this.iconTarget.classList.add('fa-caret-down')
    }
  }
}

