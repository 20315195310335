import SlimSelect from "slim-select"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static outlets = ["realtime-search"]

  static values = {
    addableTag: Boolean,
    options: Object
  };

  connect() {
    var events = {
      afterChange: (_newVal) => {
        if (this.hasRealtimeSearchOutlet) {
          this.realtimeSearchOutlet.searchImmediate();
        }
      }
    };

    if (this.addableTagValue) {
      events.addable = (value) => { return this.addableTag(value) };
    }

    this.slimselect = new SlimSelect({
      select: this.element,
      settings: { ...this.optionsValue },
      events
    });
  }

  disconnect() {
    this.slimselect.destroy();
  }

  addableTag(value) {
    // only allow a-z
    const re = /[^a-zA-Z-]/

    // return false or null if you do not want to allow value to be submitted
    if (re.test(value)) { return false }

    // Return the value string
    return value.toLowerCase()
  }
}
