import { Controller } from "@hotwired/stimulus"

import bbobHTML from '@bbob/html'
import presetHTML5 from '@bbob/preset-html5'

export default class extends Controller {
  connect() {
    const content = this.element.innerHTML

    const processed = bbobHTML(content, presetHTML5(), {
      onlyAllowTags: ['b', 'i', 'u', 's', 'color', 'quote', 'url', 'spoiler', 'code', 'pre'],
    })

    this.element.innerHTML = processed
  }
}
