import { Controller } from "@hotwired/stimulus"
import L from "leaflet"

export default class extends Controller {
  static values = { "locs": Array }

  connect() {
    this.map = L.map(this.element, {
      crs: L.CRS.Simple
    })

    L.tileLayer('/maps/Tiles/{z}/{x}/{y}.png', {
      minZoom: 15, maxZoom: 20, tms: false
    })
      .addTo(this.map)

    //this.map.setView([-4.48708, -86.05463], 20)
    this.map.setView([-131.1937370300293, 66.80571556091309], 19)

    this.map.on('click', (ev) => { this.clickDetails(ev) })

    console.log(this.scale())

    this.locsValue.forEach((loc) => {
      const latlng = this.locToLatLng(loc[0], loc[1])
      var circle = L.circle(latlng, {
        color: 'red',
        fillColor: '#f03',
        fillOpacity: 0.5,
        radius: 0.000005
      }).bindPopup(loc[2])
      circle.addTo(this.map)

      const marker = L.marker(latlng).bindPopup(loc[2])
      //marker.addTo(this.map)
    })
  }

  disconnect() {
    this.map.remove()
  }

  clickDetails(ev) {
    console.log(ev.latlng)
    // console.log(this.map.getCenter())
  }

  locToLatLng(x, y) {
    const knownPoint = this.calibrations().a
    const scale = this.scale()

    const dx = x - knownPoint.loc.x
    const dlng = dx / scale.lngScale
    const lng = knownPoint.latlng.lng + dlng

    const dy = y - knownPoint.loc.y
    const dlat = dy / scale.latScale
    const lat = knownPoint.latlng.lat + dlat

    return { lat, lng }
  }

  calibrations() {
    // lat is n-s, lng is w-e
    return {
      a: {
        // tavern keeper
        //latlng: { lat: -131.1940803527832, lng: 66.8055944442749 },
        //loc: { x: 3454.23, y: 3729 },

        // thel brifyre
        latlng: { lat: -131.19098849432373, lng: 66.80254364013672 },
        loc: { x: 2971.81, y: 4214.95 },
      },
      b: {
        // oceanside portal
        latlng: { lat: -131.1996056829834, lng: 66.81377795074463 },
        loc: { x: 4746.07, y: 2856.59 }
      }
    }
  }

  // work out the distance scale a loc is compared to a lat/lng
  scale() {
    const calibrations = this.calibrations()

    var latDiff = calibrations.a.latlng.lat - calibrations.b.latlng.lat
    var lngDiff = calibrations.a.latlng.lng - calibrations.b.latlng.lng

    var xDiff = calibrations.a.loc.x - calibrations.b.loc.x
    var yDiff = calibrations.a.loc.y - calibrations.b.loc.y

    return {
      lngScale: xDiff / lngDiff,
      latScale: yDiff / latDiff,
    }
  }
}
