import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "turboFrame"];

  static values = {
    url: String
  }

  timeout = null;

  qsFromForm() {
    return new URLSearchParams(new FormData(this.formTarget));
  }

  update() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const queryString = this.qsFromForm();
      this.turboFrameTarget.src = this.urlValue + '?' + queryString.toString();
    }, 300);
  }
}

