import { Controller } from "@hotwired/stimulus"
import MarkdownIt from 'markdown-it';

import mia from 'markdown-it-anchor'
import mitdr from 'markdown-it-toc-done-right'

export default class extends Controller {
  static targets = ['form', 'preview', 'followScroll']

  followScroll = true

  connect() {
    this.md = new MarkdownIt({
      html: true,
      linkify: true,
      typographer: true
    }).use(mia, { level: [2, 3], permalink: true, permalinkBefore: false, permalinkSymbol: '¶' })
      .use(mitdr, { level: [2, 3] })

    if (this.hasFormTarget) {
      this.updateFromForm()
    } else {
      this.updateFromPreview()

      // scroll to hash if one is present
      if (window.location.hash) {
        const hash = window.location.hash
        window.location.hash = ''
        window.location.hash = hash
      }
    }

    if (this.hasFormTarget && this.hasPreviewTarget) {
      this.formTarget.onscroll = () => {
        if (this.followScroll) {
          this.setScroll()
        }
      }
    }
  }

  setFollowScroll() {
    this.followScroll = this.followScrollTarget.checked
    this.setScroll()
  }

  setScroll() {
    const scrollPct = this.formTarget.scrollTop / this.formTarget.scrollHeight
    this.previewTarget.scrollTop = scrollPct * this.previewTarget.scrollHeight
  }

  updateFromForm() {
    this.previewTarget.innerHTML = this.md.render(this.formTarget.value)
  }

  updateFromPreview() {
    this.element.innerHTML = this.md.render(this.element.innerHTML)
  }
}

