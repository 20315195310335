import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";

export default class extends Controller {
  static values = {
    turboFrame: String,
    renderResponse: Boolean,
    submitUrl: String,
    extraFormData: Object
  }

  connect() {
    const dropzoneConfig = {
      url: this.submitUrlValue,
      clickable: true,
      dictDefaultMessage: 'Drop images here to upload (PNG or WebP only)',
      paramName: "image",
      maxFilesize: 10,
      acceptedFiles: "image/png,image/webp",
      maxFiles: 10,
      headers: {
        "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
        "Accept": "text/html" // turbo-stream can be sent in response
      },
    };

    this.dropzone = new Dropzone(this.element, dropzoneConfig);

    this.dropzone.on("success", (file, response, xhr) => {
      if (this.renderResponseValue) {
        // used for new_from_screenshot which sends a turbo-stream response
        this.element.innerHTML = response;
      } else {
        // used for all other image upload which just redraws the images panel
        this.dropzone.removeFile(file);
        Turbo.visit('#', { frame: this.turboFrameValue })
      }
    });

    this.dropzone.on("sending", (file, _xhr, formData) => {
      formData.append("size", file.size);

      Object.entries(this.extraFormDataValue).forEach(([key, value]) => {
        formData.append(key, value);
      });
    });
  }
}
