import "@hotwired/turbo-rails"
import { registerControllers } from 'stimulus-vite-helpers'
import { application } from "~/controllers/application"
import LocalTime from "local-time"

const controllers = import.meta.glob('~/controllers/*_controller.js', { eager: true })
registerControllers(application, controllers)

LocalTime.start();

Turbo.start()
Turbo.setProgressBarDelay(50);
