import { Controller } from "@hotwired/stimulus"
import lightGallery from "lightgallery"
import lgThumbnail from 'lightgallery/plugins/thumbnail'

export default class Lightbox extends Controller {
  static values = {
    options: Object,
    defaultOpen: Boolean
  }

  connect() {
    var extraOptions = this.defaultOpenValue ? this.configForDefaultOpen() : this.configForDefaultClosed();
    var options = {
      plugins: [lgThumbnail],
      loop: false,
      hash: false,
      selector: '.gallery-item',
      ...extraOptions
    }

    this.lightGallery = lightGallery(this.element, options)

    if (this.defaultOpenValue) {
      this.lightGallery.openGallery();
    }
  }

  open(event) {
    const element = event.target;
    const imageIndex = element.dataset.imageIndex;
    this.lightGallery.openGallery(Number(imageIndex));
  }

  disconnect() {
    this.lightGallery?.destroy()
  }

  configForDefaultOpen() {
    return {
      showMaximizeIcon: true,
      container: this.element,
      closable: false,
    }
  }
  configForDefaultClosed() {
    return {}
  }
}
