import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["burger", "menu"];

  static values = {
    visible: Boolean
  }

  connect() {
    // data-turbo-temporary appears to be bugged and randomly results in the
    // menu target element being removed from the DOM. Instead, we'll collapse
    // the menu ourselves so that open dropdowns/menus don't flash up from
    // turbo's cache before the proper re-render
    document.addEventListener('turbo:before-cache', () => {
      this.menuVisibleValue = false;
      this.setMenuState();
    });
  }

  click() {
    this.menuVisibleValue = !this.menuVisibleValue;
    this.setMenuState();
  }

  setMenuState() {
    // both targets optional, we just toggle whichever
    if (this.menuVisibleValue) {
      if (this.hasMenuTarget) {
        this.menuTarget.classList.add('is-active')
      }
      if (this.hasBurgerTarget) {
        this.burgerTarget.classList.add('is-active')
      }
    } else {
      if (this.hasMenuTarget) {
        this.menuTarget.classList.remove('is-active')
      }
      if (this.hasBurgerTarget) {
        this.burgerTarget.classList.remove('is-active')
      }
    }
  }
}

