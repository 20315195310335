import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    domId: String,
    id: Number
  }

  async reply() {
    if (this.replyFormRendered) {
      document.getElementById('reply-' + this.idValue).innerHTML = ''
      this.replyFormRendered = false
    } else {
      const response = await fetch('/comments/' + this.idValue + '/reply_form')
      const text = await response.text()
      Turbo.renderStreamMessage(text)
      this.replyFormRendered = true
    }
  }

  async edit() {
    const response = await fetch('/comments/' + this.idValue + '/edit_form')
    const text = await response.text()
    Turbo.renderStreamMessage(text)
  }
}
