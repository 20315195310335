import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs';
import { patch } from "@rails/request.js"

export default class extends Controller {
  connect() {
    var options = {
      animation: 150,
      onUpdate: this.onUpdate,
      draggable: '.draggable'
    }

    this.sortable = Sortable.create(this.element, options);
  }

  disconnect() {
    this.sortable?.destroy();
  }

  async onUpdate({ item, newDraggableIndex }) {
    console.log(item.dataset);
    console.log(newDraggableIndex);

    const data = new FormData()
    data.append('position', newDraggableIndex + 1)

    return await patch(item.dataset.sortableUpdateUrl, { body: data, responseKind: 'html' })
  }
}

