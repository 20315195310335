import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["source", "trigger"]

  resetTimeout = null;

  copyUrl() {
    navigator.clipboard.writeText(window.location.href);
  }

  copy() {
    navigator.clipboard.writeText(this.sourceTarget.value)
  }

  visualConfirmation() {
    if (this.resetTimeout != null) {
      return;
    }

    // currently used in Map Copy Link.

    const existingContent = this.triggerTarget.innerHTML;

    // Make the buttn green and replace the icon with a tick for 2 seconds..
    // all very gross but it works
    this.triggerTarget.classList.add('is-success');
    this.triggerTarget.innerHTML = '<span>Copied!</span> <span class="icon"><i class="fa-regular fa-check"></i></span>';

    this.resetTimeout = setTimeout(() => {
      this.triggerTarget.innerHTML = existingContent;
      this.triggerTarget.classList.remove('is-success');
      this.resetTimeout = null;
    }, 1000);
  }
}

