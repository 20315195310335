// https://www.stimulus-components.com/docs/stimulus-rails-nested-form

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["target", "template"]

  static values = {
    placeholder: { type: String, default: 'NEW_RECORD' },
    wrapperSelector: { type: String, default: ".nested-form-wrapper", },
  }

  add(e) {
    e.preventDefault()

    const re = new RegExp(`${this.placeholderValue}`, 'g')
    const content = this.templateTarget.innerHTML.replace(re, new Date().getTime().toString())
    this.targetTarget.insertAdjacentHTML("beforebegin", content)

    // const event = new CustomEvent("rails-nested-form:add", { bubbles: true })
    // this.element.dispatchEvent(event)
  }

  remove(e) {
    e.preventDefault()

    const wrapper = e.target.closest(this.wrapperSelectorValue)

    if (wrapper.dataset.newRecord === "true") {
      wrapper.remove()
    } else {
      wrapper.style.display = "none"

      const input = wrapper.querySelector("input[name*='_destroy']")
      input.value = "1"
    }

    // const event = new CustomEvent("rails-nested-form:remove", { bubbles: true })
    // this.element.dispatchEvent(event)
  }
}
